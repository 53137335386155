/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#input-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;
} */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

#chatting-area {
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.user-speech,
.bot-speech {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  position: relative;
}

.user-speech {
  background-color: #0084ff;
  color: #fff;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

.user-speech::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #0084ff transparent transparent;
  top: 0;
  right: -10px;
}

.bot-speech {
  background-color: #f0f0f0;
  color: #000;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

.bot-speech::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #f0f0f0;
  top: 0;
  left: -10px;
}

#input-area {
  padding: 20px;
}

.answer-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
}
